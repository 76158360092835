import React from 'react';
import { Image, Layout } from '../../components';
import { OverviewListViewProps } from '../../types';
import * as uuid from 'uuid';
import { Link } from 'gatsby';

const OverviewListView: React.FunctionComponent<OverviewListViewProps> = ({
  list,
}) => {
  return (
    <Layout
      title="Services"
      banner={{
        src: '/media/core-services/placeholder.jpg',
        srcAlt: 'Services',
        title: 'Services',
      }}
      className="overviewListLayout"
    >
      <div className="overview services">
        <div className="wrapper">
          <div className="twoColSingle">
            <div className="twoColSingle--main">
              <ul>
                {list.map((item) => {
                  return (
                    <li key={uuid.v4()} className="services--item">
                      <Link to={`/services/${item.slug}`}>
                        <Image
                          src={item.cover_image.uri}
                          alt={item.cover_image.alt_text}
                          minWidth={600}
                        />
                        <div>
                          <h2>{item.title}</h2>
                          <p>{item.overview_text}</p>
                          <div
                            className="btn btn_block"
                            style={{ maxWidth: 'max-content' }}
                          >
                            <span>Learn more</span>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OverviewListView;
