import React from 'react';
import {
  Article,
  AsideWithIcons,
  Breadcrumb,
  HubspotForm,
  Layout,
  WidgetManager,
} from '../../components';
import { PathService } from '../../services';
import { OverviewViewProps, PropType } from '../../types';

const OverviewView: React.FunctionComponent<OverviewViewProps> = ({
  page,
  content,
}) => {
  const fullWidthWidgets = [
    'table',
    'cta_large',
    'columns_with_icons',
    'double_banners',
    'interested_in',
    'reference',
  ];
  const fullWidthWidgetIndex = content.findIndex((e) =>
    fullWidthWidgets.includes(e.name),
  );
  const filteredContent = content.filter(
    (_, index) => index <= fullWidthWidgetIndex - 1,
  );
  const restContent = content.filter(
    (_, index) => index > fullWidthWidgetIndex - 1,
  );

  function resizeHandler() {
    const main = document.querySelector('.twoColSingle--main');
    const aside = document.querySelector('.asideWithIcons');
    const cta = document.querySelector('.cta');

    if (main && aside && cta) {
      if (window.innerWidth <= 1240) {
        cta.parentNode?.insertBefore(aside, cta);
      } else {
        main.parentNode?.insertBefore(aside, main.nextSibling);
      }
    }
  }

  React.useEffect(() => {
    if (page.aside.title) {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);

      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }
  }, []);

  return (
    <Layout
      banner={{
        src: page.cover_image.uri,
        title: page.title,
        srcAlt: page.cover_image.alt_text,
        subtitle: page.subtitle,
      }}
      description={page.description}
      title={page.title}
      OGImageUri={page.cover_image.uri}
      className="articleWithPatternLayout"
    >
      <div className="overview service">
        <div className="wrapper">
          <Breadcrumb
            items={[
              {
                label: 'Services',
                path: '/services',
              },
              {
                label: page.title,
                path: PathService.get(),
              },
            ]}
          />
          <div className="twoColSingle">
            <Article content={filteredContent} />
            {page.aside.title && <AsideWithIcons data={page.aside} />}
          </div>
        </div>
        <div className="wrapper wrapper_form">
          <HubspotForm formId="dc14653c-7e92-448f-9423-b3c115abbe9e" />
        </div>
        <div className="rest">
          {restContent
            .filter((e) => e.name !== 'sources')
            .map((contentItem, contentItemIndex) => {
              switch (contentItem.type) {
                case PropType.WIDGET: {
                  return (
                    <WidgetManager
                      minImageWidth={1200}
                      key={'' + contentItemIndex}
                      widget={contentItem}
                    />
                  );
                }
                default: {
                  const value: string = contentItem.value as any;
                  return (
                    <div
                      key={contentItemIndex}
                      dangerouslySetInnerHTML={{ __html: value }}
                    ></div>
                  );
                }
              }
            })}
        </div>
      </div>
    </Layout>
  );
};

export default OverviewView;
