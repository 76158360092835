import React from 'react';
import { PathService } from '../../services';
import { OverviewViewContext, TemplateProps } from '../../types';
import { OverviewView } from '../../views/overview';

const Overview: React.FunctionComponent<TemplateProps<OverviewViewContext>> = ({
  location,
  pageContext,
}) => {
  PathService.set(location.pathname);
  return (
    <OverviewView
      content={JSON.parse(pageContext.content)}
      page={JSON.parse(pageContext.page)}
    />
  );
};

export default Overview;
